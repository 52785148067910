import { Injectable, inject, signal } from '@angular/core';
import { Api } from '@fry/core/api';

const RISRAI_API_FILL_FORM = '/ai/ai_form_filler';

export interface RecognitionState {
    alertMessage: string;
    buttonLabel: string;
    state: 'error' | 'success' | 'idle' | 'listening' | 'processing' | 'unavailable';
}

@Injectable()
export class AiFormFillerService {
    private formData = signal<any>({});
    private api = inject(Api);
    $formData = this.formData.asReadonly();

    private recognition: any;
    private masterTranscript = '';

    private _recognitionState = signal<RecognitionState>({
        alertMessage: 'Record your review with your student and let our AI populate a first draft of the form on the left for you.',
        buttonLabel: 'Start Recording',
        state: 'idle'
    });
    $recognitionState = this._recognitionState.asReadonly();


    private formSpec: any;
    constructor() { }

    setFormSpec(formSpec: any) {
        this.formSpec = formSpec;
    }

    async send(transcript: string): Promise<any> {
        const formData = {
            form_schema: this.formSpec,
            transcript: transcript
        };
        const response = await this.api.post<any>(RISRAI_API_FILL_FORM, formData);
        if (response.state !== 'ok') {
            throw new Error(response.error);
        }
        return response.data;
    }

    updateFormData(data: any) {
        this.formData.set(data);
    }

    initSpeachRecognition() {
        const SpeechRecognition = window['SpeechRecognition'] || window['webkitSpeechRecognition'];
        if (!SpeechRecognition) {
            this._recognitionState.set({
                alertMessage: "Sorry, your browser does not support Speech Recognition. Try using Chrome or Safari.",
                buttonLabel: '',
                state: 'unavailable'
            });
            return;
        }
        this.recognition = new SpeechRecognition();
        this.masterTranscript = '';

        this.recognition.continuous = true;
        this.recognition.interimResults = false;
        this.recognition.lang = "en-UK";

        this.recognition.onresult = (event: any) => {
            console.info("Speech recognition result");
            let currentTranscript = "";
            for (let i = event.resultIndex; i < event.results.length; i++) {
                currentTranscript = event.results[i][0].transcript;
            }
            this.masterTranscript += ' ' + currentTranscript;
        };
        this.recognition.onerror = (event: any) => {
            console.error("Speech recognition error: " + event.error);
            this._recognitionState.set({
                alertMessage: "Speech recognition error: " + event.error,
                buttonLabel: '',
                state: 'error'
            });
        };
    }

    startSpeechRecognition() {
        if (this.recognition) {
            this.recognition.start();
            this._recognitionState.set({
                alertMessage: "Listening...",
                buttonLabel: 'Stop Recording',
                state: 'listening'
            });
        }
    }

    stopSpeechRecognition() {
        if (this.recognition) {
            this.recognition.stop();
            this._recognitionState.set({
                alertMessage: "Processing...",
                buttonLabel: 'Processing...',
                state: 'processing'
            });
            setTimeout(() => {
                this.processTranscript(this.masterTranscript);
            }, 1500);
        }
    }

    async processTranscript(transcript: string): Promise<any> {
        if (transcript) {
            try {
                const data = await this.send(transcript);
                this._recognitionState.set({
                    alertMessage: "Success!",
                    buttonLabel: 'Start Recording',
                    state: 'success'
                });
                this.updateFormData(data);
            } catch (error) {
                this._recognitionState.set({
                    alertMessage: "Failed to process the form.",
                    buttonLabel: 'Start Recording',
                    state: 'error'
                });
            }
        }
    }
}
