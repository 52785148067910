import { downgradeInjectable } from '@angular/upgrade/static';
import { NetworkService } from 'src/ang2/app/core/network.service';

(function() {
  'use strict';

  function NetworkServiceOld(
    $rootScope,
    $q,
    $log,
    Notify,
    NETWORK,
    DB_ERRORS
  ) {
    var service = {};

    service.STATUS = NETWORK;
    service.modeStatus = 'online';

    service.setModeOffline = function() {
      if (service.modeStatus !== service.STATUS.offline) {
        service.modeStatus = service.STATUS.offline;
        $rootScope.$broadcast('BecomeOffline');
      }
    };

    service.setModeOnline = function() {
      if (service.modeStatus !== service.STATUS.online) {
        service.modeStatus = 'online';
        $rootScope.$broadcast('BecomeOnline');
      }
    };

    service.resetMode = function() {
      service.modeStatus = !navigator.onLine ? 'offline' : 'online';
    };

    service.setOffline = function() {
      // var show = service.networkStatus !== undefined;
      if (service.networkStatus !== service.STATUS.offline) {
        // if (show) {
        //   Notify.warning('You are currently offline!');
        // }

        $log.debug('Setting offline');
        service.networkStatus = service.STATUS.offline;
      }
      service.setModeOffline();
    };

    service.setOnline = function() {
      // var show = service.networkStatus !== undefined;
      if (service.networkStatus !== service.STATUS.online) {
        // if (show) {
        //   Notify.success('You are back online!');
        // }

        $log.debug('Setting online');
        service.networkStatus = service.STATUS.online;
        $rootScope.$broadcast('BrowserBecomeOnline');
      }
      // service.setModeOnline();
    };

    service.isOffline = function() {
      return service.modeStatus === service.STATUS.offline;
    };

    service.isNetworkOffline = function() {
      return service.networkStatus === service.STATUS.offline;
    };

    service.forceOnline = function(options) {
      if (service.isOffline()) {
        if (options && options.showToastr) {
          Notify.error('This is not available when working offline');
        }

        return $q.reject(DB_ERRORS.notAvailableOffline);
      }

      return $q.when();
    };

    service.forceNetworkOnline = function() {
      if (service.networkStatus === service.STATUS.offline) {
        return $q.reject(DB_ERRORS.notAvailableOffline);
      }

      return $q.when();
    };

    return service;
  }

  NetworkServiceOld.$inject = [
    '$rootScope', '$q', '$log',
    'NotifyService',
    'NETWORK', 'DB_ERRORS'];

  angular.module('blocks.network')
    .service('NetworkService', downgradeInjectable(NetworkService));
})();
