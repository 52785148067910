(function() {
  'use strict';

  function StatusService($q, $rootScope, $uibModal, Auth, LocalStore,
    LocalUsers, Network, SettingsService) {
    var service = {};

    var mapping = {
      online: 'You are working in online mode',
      offline: 'Your are working in offline mode'
    };

    var mappingClass = {
      online: 'text-success',
      offline: 'text-warning'
    };

    function getStatus(status) {
      return {
        status: status,
        text: mapping[status],
        class: mappingClass[status]
      };
    }

    service.setStorageMode = function() {
      return Auth.getStorageMode()
          .then(function(mode) {
            service.storageMode = mode;
            service.broadcast();
          });
    };

    service.setOfflineState = function() {
      service.offlineState = window.offlineState;
      service.broadcast();
    };

    service.setOfflineCapability = function() {
      var tests = SettingsService.getTests();
      SettingsService.testAll(tests, { level: 1 })
        .then(function(result) {
          service.offlineCapability = result;
          service.broadcast();
        })
        .catch(function(err) {
          console.log(err);
        });
    };

    service.setMode = function() {
      service.mode = getStatus(Network.modeStatus);
      service.broadcast();
    };

    service.setNetwork = function() {
      service.network = getStatus(Network.networkStatus);
      service.broadcast();
    };

    function clearErrors() {
      service.error = undefined;
    }

    function setError(error) {
      if (error && error.status === 401) {
        service.authError = {
          status: 401,
          message: 'Please login to sync your changes with your account'
        };
      } else {
        service.error = error || {};
        service.error.warn = [403, 409].indexOf(service.error.status) !== -1;
      }
      service.broadcast();
    }

    service.setError = function(error) {
      setError(error);
    };

    service.setModeOffline = function() {
      clearErrors();
      Network.setModeOffline();
      service.broadcast();
    };

    service.setModeOnline = function() {
      if (_.isEmpty(Auth.currentUser())) {
        return Network.setModeOnline();
      }

      return Auth.isLoggedIn()
        .then(function() {
          clearErrors();
          return Network.setModeOnline();
        })
        .then(function() {
          service.authError = undefined;
        })
        .catch(function(err) {
          setError(err);
        })
        .finally(function() {
          service.broadcast();
        });
    };

    service.relogin = function() {
      var def = $q.defer();
      var popup = $uibModal.open({
        animation: true,
        templateUrl: 'app/blocks/network/login.popup.html',
        size: 'dialog',
        controller: ['organisation', '$uibModalInstance',
          function(organisation, $uibModalInstance) {
            this.organisation = organisation;
            this.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            this.success = function() {
              $uibModalInstance.close();
            };
          }
        ],
        controllerAs: 'ctrl',
        resolve: {
          organisation: ['OrganisationsService', function(Organisations) {
            return Organisations.getLoginOptions(Auth.currentOrganisation(), { force: true });
          }]
        }
      });

      popup.result.finally(function() {
        service.setModeOnline();
        def.resolve();
      });
      return def.promise;
    };

    service.pushAll = function() {
      return LocalStore.pushAll()
        .then(function() {
          clearErrors();
        })
        .catch(function(err) {
          setError(err || { status: 0, message: 'Unknown error' });
        });
    };

    function updateCount() {
      return LocalStore.count()
        .then(function(res) {
          service.doc_count = res;
          service.broadcast();
        });
    }

    function updateCountInOthers() {
      return LocalUsers.getOrganisationsFor(Auth.currentUser())
        .then(function(orgs) {
          var current = Auth.currentOrganisation();
          var others = _.filter(orgs, function(org) {
            return org._id !== current;
          });
          return $q.all(_.map(others, function(org) {
            return LocalStore.count({ organisation: org._id });
          }));
        })
        .then(function(res) {
          service.others_count = _.sum(res);
        });
    }

    service.getCount = function() {
      return service.doc_count;
    };

    service.getInfo = function() {
      return {
        toSync: service.doc_count,
        toSyncOthers: service.others_count,
        storageMode: service.storageMode,
        offlineState: window.offlineState,
        offlineCapability: service.offlineCapability,
        mode: service.mode,
        network: service.network,
        error: service.error,
        authError: service.authError
      };
    };

    service.broadcast = function() {
      // console.log('Broadcasting info');
      $rootScope.$broadcast('KZStatusInfoUpdated', service.getInfo());
    };

    function setAll() {
      service.error = undefined;
      service.authError = undefined;
      service.setStorageMode();
      service.setOfflineState();
      service.setOfflineCapability();
      updateCount();
      updateCountInOthers();
    }

    /** Keep up to date */
    setAll();

    // When they do such a thing, lets make sure we are ok
    // This happens e.g. when logging in
    // $rootScope.$on('KZClearCache', function() {
    //   setAll();
    // });

    $rootScope.$on('KZLoggedIn', function() {
      service.authError = undefined;
    });

    $rootScope.$watch(function() {
      return Network.modeStatus;
    }, function() {
      service.setMode();
    });

    $rootScope.$watch(function() {
      return Network.networkStatus;
    }, function() {
      service.setNetwork();
    });

    $rootScope.$on('KZLocalStoreUpdated', function() {
      updateCount();
      updateCountInOthers();
    });

    $rootScope.$on('BrowserBecomeOnline', function() {
      service.setModeOnline();
    });

    $rootScope.$on('KZLocalPushed', function(_evt, args) {
      service.setError(args.error);
    });

    $rootScope.$on('KZLocalSettingsChanged', function() {
      service.setStorageMode();
      service.setOfflineCapability();
    });

    $rootScope.$on('BecomeOnline', function() {
      // service.pushAll();
    });

    return service;
  }

  StatusService.$inject = [
    '$q',
    '$rootScope',
    '$uibModal',
    'AuthService',
    'LocalStoreService',
    'LocalUsersService',
    'NetworkService',
    'SettingsService'
  ];

  angular.module('blocks.network')
    .service('StatusService', StatusService);
})();
