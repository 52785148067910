import * as _ from 'lodash';
import { TimeZones } from '../../core/timezones';


(function() {
  'use strict';

  function OrganisationSettingsController(
    $state, $stateParams, $scope, $rootScope, Organisations, Form, Utils,
    LocalizationService) {
    var ctrl = this;

    ctrl.orgId = $stateParams.orgId;
    ctrl.settings = {};

    function setForm(disableEnableLocalLoginOptionField) {
      ctrl.generalForm = new Form();

      Organisations.getClusterAvailableLanguages()
        .then(function(languages) {
          var defaultLanguageCode;
          var availableLanguagesOptions = _.map(languages, function(language) {
            if (language.is_default) {
              defaultLanguageCode = language.language_code;
            }
            return {
              _id: language.language_code,
              key: language.language_code,
              name: language.language_name
            };
          });
          var generalFields = [
            { id: 'general-legend', type: 'text_heading', label: 'General' },
            { id: 'shortName', type: 'string', label: 'Short Name' },
            { id: 'name', type: 'string', label: 'Name' },
            {
              id: 'allowedAccountTypes',
              type: 'discrete_multiple',
              label: 'What kind of account is allowed to be created for admins? ',
              helpText: '(the auto create will always work)',
              options: [
                { _id: '__all__', key: '__all__', name: 'Any' },
                { _id: 'local', key: 'local', name: 'Local' },
                { _id: 'proxy', key: 'proxy', name: 'SSO' }
              ]
            },
            {
              id: 'accountTypeToCreateForSelfRegistration',
              type: 'discrete',
              label: 'What kind of account you would like to be create for the quick fill? ',
              options: [
                { _id: '', key: '', name: 'No account need to be created on quick fill' },
                { _id: 'local', key: 'local', name: 'Local' },
                { _id: 'proxy', key: 'proxy', name: 'SSO' }
              ]
            },
            {
              id: 'allowedProducts',
              type: 'discrete_multiple',
              label: 'Select products the organisation is allowed to use',
              options: [
                { _id: 'kaizen', key: 'kaizen', name: 'risr/advance' },
                { _id: 'eas', key: 'eas', name: 'risr/apply' }
              ]
            },
            {
              id: 'languages',
              type: 'discrete_multiple',
              label: 'Allowed Languages',
              helpText: 'Users will be able to switch languages',
              options: availableLanguagesOptions,
              defaultValue: [defaultLanguageCode]
            },
            {
              id: 'enabledLabs',
              type: 'boolean',
              label: 'Enable labs'
            },
            {
              id: 'enableLocalLoginOption',
              type: 'boolean',
              label: 'Enable local login option',
              disabled: disableEnableLocalLoginOptionField
            },
            {
              id: 'disableAutoRegister',
              type: 'boolean',
              label: 'Disable auto register via SSO',
              helpText: 'Require a local account when logging in via SSO'
            },
            {
              id: 'useIntegrationForSelfRegistration',
              type: 'boolean',
              label: 'Use Integration for self registration accounts? (if it exists one)',
              helpText: 'Users will not be able to log in if disabled',
              hideExpression: function(_$viewValue, _$modelValue, $scope) {
                return $scope.model.accountTypeToCreateForSelfRegistration !== 'proxy';
              }
            },
            {
              id: 'supportDeskLink',
              type: 'string',
              label: 'Support Desk Link',
              helpText: 'The URL of the Issue Collector embedded Javascript source file. ' +
                'Disabled when left blank.'
            },
            {
              id: 'disabled',
              type: 'boolean',
              label: 'Organisation is disabled',
              helpText: 'Users will not be able to log in if disabled'
            }
          ];

          var generalGroup = ctrl.generalForm.addGroup();
          ctrl.generalForm.extend(generalFields, generalGroup);
        });

      ctrl.emailForm = new Form();

      var emailFields = [
        { id: 'email-legend', type: 'text_heading', label: 'Email' },
        { id: 'email_from', type: 'string', label: 'Specify email from address' },
        { id: 'email_disabled', type: 'boolean', label: 'Do not send emails to real recipients' },
        { id: 'email_bcc_recipients', type: 'string', label: 'Blind copy recipients' }
      ];

      var emailGroup = ctrl.emailForm.addGroup();
      ctrl.emailForm.extend(emailFields, emailGroup);

      ctrl.themeForm = new Form();

      var themeFields = [
        { id: 'theme-legend', type: 'text_heading', label: 'Organisation theme' },
        {
          id: 'colors',
          type: 'json',
          label: 'Colors'
        },
        { id: 'logos-legend', type: 'text_heading', label: 'Organisation logos' },
        { id: 'logoDesktopPath', type: 'string', label: 'Desktop logo path' },
        { id: 'logoMobilePath', type: 'string', label: 'Mobile logo path' }
      ];

      var themeGroup = ctrl.themeForm.addGroup();
      ctrl.themeForm.extend(themeFields, themeGroup);

      ctrl.localizationForm = new Form();

      var dateTimeFormatOptions = LocalizationService.getDateTimeFormatOptions();

      var localizationFields = [
        { id: 'localization-legend', type: 'text_heading', label: 'Localization' },
        {
          id: 'inputDateFormat',
          type: 'discrete',
          label: 'Input Date format',
          required: true,
          options: dateTimeFormatOptions.inputdate
        },
        {
          id: 'dateFormat',
          type: 'discrete',
          label: 'Display Date format',
          required: true,
          options: dateTimeFormatOptions.date
        },
        {
          id: 'timeFormat',
          type: 'discrete',
          label: 'Display Time format',
          required: true,
          options: dateTimeFormatOptions.time
        },
        {
          id: 'timezone',
          type: 'discrete',
          label: 'Timezone',
          options: _.map(TimeZones, function(itm) {
            return { _id: itm, key: itm, name: itm };
          })
        }
      ];
      var localizationGroup = ctrl.localizationForm.addGroup();
      ctrl.localizationForm.extend(localizationFields, localizationGroup);
    }

    Organisations.find(ctrl.orgId)
      .then(function(data) {
        data.emailSetup = data.emailSetup || {};
        data.themeSetup = data.themeSetup || {};
        data.localizationSetup = data.localizationSetup || {};

        ctrl.emailSetup = data.emailSetup || {};
        if (ctrl.emailSetup.email_bcc_recipients) {
          ctrl.emailSetup.email_bcc_recipients = ctrl.emailSetup.email_bcc_recipients.join(', ');
        }

        var hasLocalLoginOption = false,
            hasProxyLoginOption = false,
            noLoginOptions = _.isUndefined(data.loginOptions) || _.isEmpty(data.loginOptions);
        _.forEach(data.loginOptions, function(loginOption) {
          if (loginOption.type === 'local') {
            hasLocalLoginOption = true;
          } else if (loginOption.type === 'proxy') {
            hasProxyLoginOption = true;
          }
        });

        data.enableLocalLoginOption = hasLocalLoginOption || noLoginOptions;

        var disableEnableLocalLoginOptionField = !hasProxyLoginOption;
        setForm(disableEnableLocalLoginOptionField);

        ctrl.settings = data;
        Utils.setPageTitle('Edit organisation: ' + data.name);
        ctrl.loaded = true;
      });

    var redirectionState = 'epf.organisations.index';
    ctrl.cancel = function() {
      $state.go(redirectionState);
    };

    $scope.$watch(function() {
      return (ctrl.settings.themeSetup || {}).theme;
    }, function(value) {
      $rootScope.$broadcast('OrganisationTryTheme', { theme: value });
    });

    var transform = function(obj, transformers) {
      transformers.forEach(function(fn) {
        obj = fn(obj);
      });

      return obj;
    };

    var transformEmailSetup = function(emailSetup) {
      var setup = angular.copy(emailSetup);
      setup.email_bcc_recipients = _.map(emailSetup.email_bcc_recipients.split(','), function(itm) {
        return itm.trim();
      });
      return setup;
    };

    var colorsStringToJson = function(obj) {
      // When we save the form without making changes to the colors field
      // it's not a string, so we don't need to parse it
      if (obj.themeSetup.colors && typeof obj.themeSetup.colors === 'string') {
        try {
          obj.themeSetup.colors = JSON.parse(obj.themeSetup.colors);
        } catch (e) {
          // We ignore it
        }
      }
      return obj;
    };

    ctrl.save = function(isValid) {
      ctrl.formIsSubmitted = true;

      if (isValid) {
        ctrl.settings.emailSetup = transformEmailSetup(ctrl.emailSetup);
        ctrl.settings._id = ctrl.orgId;
        // TODO: Is there a better way to do this?
        // Apply any modifications to the form data
        ctrl.settings = transform(ctrl.settings, [colorsStringToJson]);


        Organisations.save(ctrl.settings)
          .then(function() {
            $rootScope.$broadcast('OrganisationSettingsChanged', { org: ctrl.settings });
            $state.go(redirectionState);
          })
          .catch(Utils.showError);
      } else {
        Utils.showError({ message: 'Form is not valid' });
      }
    };
  }

  OrganisationSettingsController.$inject = [
    '$state',
    '$stateParams',
    '$scope',
    '$rootScope',
    'OrganisationsService',
    'FormsService',
    'UtilsService',
    'LocalizationService'
  ];

  angular.module('component.organisations')
    .controller('OrganisationSettingsController', OrganisationSettingsController);
})();
